<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Grupo"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Asignaturas"
          item-text="nombre"
          item-value="id"
          :items="asignaturas"
          v-model="asignaturaId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Estudiante"
          item-text="nombre"
          item-value="id"
          :items="estudiantes"
          v-model="estudianteId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="RendimientosFiltro"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="habilitarBtnImportar"
                  >
                    Importar Notas
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            label="Adjuntar"
                            v-model="archivos"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            label="Incluir asignaturas en la plantilla"
                            item-text="nombre"
                            item-value="id"
                            :items="asignaturas"
                            v-model="asignaturaId"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            class="ma-2"
                            color="primary"
                            dark
                            @click="descargarPlantilla"
                          >
                            <v-icon dark left> mdi-file-excel </v-icon>
                            Descargar plantilla
                          </v-btn>
                        </v-col>
                        <v-col cols="12"> </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :loading="loading4"
                      :disabled="loading4"
                    >
                      Guardar
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          
          <template v-for="(tipoItem,index) in tiposItemRendimiento" v-slot:[`item.valorItem${tipoItem.id}`]="{ item }">
            <div style="max-width: 30px" :key="tipoItem.id">
              <v-text-field
                v-model="item.redimientosAsignatura[index].valoracion"
                :rules="[() => (item.redimientosAsignatura[index].valoracion >= 0.00 && item.redimientosAsignatura[index].valoracion <= 5.00) || 'error']"
                @change="actualizarNota(item,index)"
              >
              </v-text-field>
            </div>             
          </template>
          
          <template v-slot:[`item.notaDefinitiva`]="{ item }">
            <div style="max-width: 40px">
              <v-text-field
                v-model="item.notaDefinitiva"
                :rules="[() => (item.notaDefinitiva >= 0.00 && item.notaDefinitiva <= 5.00) || 'error']" 
                :readonly=!notaDefinitivaEditable
                @change="actualizarNotaDefinitivaManual(item,index)"
              >               
              </v-text-field>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    loading4: false,
    archivos: null,
    dialog: false,
    formTitle: "Importar notas",
    periodoId: 0,
    grupoId: 0,
    estudianteId: "0",
    asignaturaId: 0,
    periodos: [],
    grupos: [],    
    estudiantes: [],
    asignaturas: [],
    tiposItemRendimiento : [],
    rendimientoEscolarAsignaturasItems : [],
    headers: [
      { text: "Asignatura", value: "asignaturaNombre" },
      { text: "Estudiante", value: "estudianteNombreCompleto" },
      {text:`Definitiva`,value:"notaDefinitiva",sortable: false, width: "20"}        
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    RendimientosFiltro() {
      if (this.estudianteId !== "0" || this.asignaturaId !== 0) {
        if (this.estudianteId !== "0" && this.asignaturaId !== 0) {
          return this.rendimientoEscolarAsignaturasItems.filter((x) => {
            return (
              x.estudianteId === this.estudianteId &&
              x.asignaturaId === this.asignaturaId
            );
          });
        } else {
          return this.rendimientoEscolarAsignaturasItems.filter((x) => {
            return (
              x.estudianteId === this.estudianteId ||
              x.asignaturaId === this.asignaturaId
            );
          });
        }
      } else {
        return this.rendimientoEscolarAsignaturasItems;
      }
    },
    habilitarBtnImportar() {
      return !(this.grupoId > 0 && this.periodoId > 0);
    },
    urlPlantilla() {
      return `api/PlantillasNotas/periodo/${this.periodoId}/grupo/${this.grupoId}/asignatura/${this.asignaturaId}`;
    },
    notaDefinitivaEditable(){
      return  this.tiposItemRendimiento.length > 0? false:true;
    }
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetGruposDocente",      
      "GetRendimientoAsignaturasGrupo",
      "UpdateRendimiento",
      "ModificarMensaje",
      "GetTiposItemRendimientoActivos",
      "UpdateRendimientoAsignaturaItems"
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data.filter(x=> x.estado == 1)));
      this.GetTiposItemRendimientoActivos().then((x) =>(this.tiposItemRendimiento = x.data));      
    },
    obtenerEncabezados(){
      this.headers =[{ text: "Asignatura", value: "asignaturaNombre" },{ text: "Estudiante", value: "estudianteNombreCompleto" }];
      
      this.tiposItemRendimiento.forEach(element => {
        this.headers.push({text:`${element.nombre} ${element.porcentaje * 100}%`,value:`valorItem${element.id}`,sortable: false, width: "10"});
      });

      this.headers.push({text:`Definitiva`,value:"notaDefinitiva",sortable: false, width: "20"});
    },
    descargarPlantilla() {
      axios
        .get(this.urlPlantilla, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          let nombreArchivo = response.headers["content-disposition"]
            .split(";")[1]
            .replace('filename=', "");
          nombreArchivo = nombreArchivo            
            .replaceAll(" ", "");
          nombreArchivo = nombreArchivo.replaceAll("-", "_");
          nombreArchivo = nombreArchivo.replaceAll('"', '');

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nombreArchivo;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    actualizarNota(item,indexElemento) {
            
      let notaDefinitivaCalculada =0;      
      let notaMinima = 0.00;
      let notaMaxima = 5.00;      
      let rendimientoASignaturaItem = item.redimientosAsignatura[indexElemento];
      
      item.redimientosAsignatura.forEach(element => {
        
        if(element.valoracion===undefined || ( element.valoracion!==null && isNaN(element.valoracion))){
          element.valoracion =0;
        }        

        notaDefinitivaCalculada += (element.valoracion * element.porcentaje); 
      });

      item.notaDefinitiva = notaDefinitivaCalculada;
      
      if(rendimientoASignaturaItem.valoracion>=notaMinima && rendimientoASignaturaItem.valoracion<= notaMaxima){

        let datos ={};
        datos.id = rendimientoASignaturaItem.rendimientoEscolarAsignaturasId;
        datos.EstudianteId = item.estudianteId;
        datos.MallaPeriodoId = item.mallaPeridoId;
        datos.ItemRendimientoId = rendimientoASignaturaItem.tipoItemRendimientoId;
        datos.Valoracion = rendimientoASignaturaItem.valoracion;
        datos.Porcentaje = this.tiposItemRendimiento[indexElemento].porcentaje;

        this.UpdateRendimientoAsignaturaItems(datos).then((x) => {
          this.ModificarMensaje({ texto: "Registro Exitoso!" });
        });
      }            
    },
    actualizarNotaDefinitivaManual(item,indexElemento) {
        if(this.notaDefinitivaEditable && item.notaDefinitiva>=0 && item.notaDefinitiva<=5.0){

          let datos ={};
          datos.id=item.rendimientoId;
          
          if(item.rendimientoId==null){
            datos.id=0;
          }

          datos.MallaPeriodoId =item.mallaPeridoId;
          datos.EstudianteId=item.estudianteId;

          datos.TC=0;
          datos.PCo=0;
          datos.Ama=0;
          datos.PHa=0;
          datos.Nota = item.notaDefinitiva;
          
          datos.Fallas=0;         
          datos.FallaJustificada=0;
          datos.FallaEvacion =0;
          datos.FallaRetardo =0;

          this.UpdateRendimiento(datos).then((x) => {
                this.ModificarMensaje({ texto: "Registro Exitoso!" });
          });
        }        
    },
    save() {
      this.loading4 = true;
      var formData = new FormData();
      formData.append("image", this.archivos);
      axios
        .post("api/MallaPeriodos/subirNotas", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((x) => {
          this.consultarNotas();
          this.loading4 = false;
          this.ModificarMensaje({ texto: "Registro Exitoso!" });
          this.archivos = null;
          this.close();
        });
    },
    close() {
      this.dialog = false;
    },
    obetnerFiltros(datos, id, nombre, textoDefault, idDefault) {
      const filteredArr = datos.reduce((acc, current) => {
        let dato = {
          id: current[id],
          nombre: current[nombre],
        };

        const x = acc.find((item) => item.id === dato.id);
        if (!x) {
          return acc.concat([dato]);
        } else {
          return acc;
        }
      }, []);

      var merged = [].concat.apply(
        [{ id: idDefault, nombre: textoDefault }],
        filteredArr
      );

      return merged;
    },
    consultarNotas() {
      let datos = {};
      datos.periodo = this.periodoId;
      datos.grupo = this.grupoId;
      this.obtenerEncabezados();

      this.GetRendimientoAsignaturasGrupo(datos).then((x)=>{      
        this.rendimientoEscolarAsignaturasItems = x.data;
        
        this.estudiantes = this.obetnerFiltros(
          this.rendimientoEscolarAsignaturasItems,
          "estudianteId",
          "estudianteNombreCompleto",
          "Todos",
          "0"
        );

        this.asignaturas = this.obetnerFiltros(
          this.rendimientoEscolarAsignaturasItems,
          "asignaturaId",
          "asignaturaNombre",
          "Todas",
          0
        );

      });      
    },
  },
  watch: {
    periodoId(nuevoValor) {      
      this.grupoId = 0;
      this.rendimientoEscolarAsignaturasItems = [];
      this.GetGruposDocente(nuevoValor).then((x) => (this.grupos = x.data));
    },
    grupoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.consultarNotas();
      }
    },
  },
};
</script>