var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Periodo","item-text":"nombre","item-value":"id","items":_vm.periodos},model:{value:(_vm.periodoId),callback:function ($$v) {_vm.periodoId=$$v},expression:"periodoId"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Grupo","item-text":"nombre","item-value":"id","items":_vm.grupos},model:{value:(_vm.grupoId),callback:function ($$v) {_vm.grupoId=$$v},expression:"grupoId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Asignaturas","item-text":"nombre","item-value":"id","items":_vm.asignaturas},model:{value:(_vm.asignaturaId),callback:function ($$v) {_vm.asignaturaId=$$v},expression:"asignaturaId"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Estudiante","item-text":"nombre","item-value":"id","items":_vm.estudiantes},model:{value:(_vm.estudianteId),callback:function ($$v) {_vm.estudianteId=$$v},expression:"estudianteId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.RendimientosFiltro,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"secondary","dark":"","disabled":_vm.habilitarBtnImportar}},'v-btn',attrs,false),on),[_vm._v(" Importar Notas ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","label":"Adjuntar"},model:{value:(_vm.archivos),callback:function ($$v) {_vm.archivos=$$v},expression:"archivos"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Incluir asignaturas en la plantilla","item-text":"nombre","item-value":"id","items":_vm.asignaturas},model:{value:(_vm.asignaturaId),callback:function ($$v) {_vm.asignaturaId=$$v},expression:"asignaturaId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.descargarPlantilla}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-file-excel ")]),_vm._v(" Descargar plantilla ")],1)],1),_c('v-col',{attrs:{"cols":"12"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading4,"disabled":_vm.loading4},on:{"click":_vm.save},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_vm._v(" Guardar ")])],1)],1)],1)],1)]},proxy:true},_vm._l((_vm.tiposItemRendimiento),function(tipoItem,index){return {key:("item.valorItem" + (tipoItem.id)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:tipoItem.id,staticStyle:{"max-width":"30px"}},[_c('v-text-field',{attrs:{"rules":[function () { return (item.redimientosAsignatura[index].valoracion >= 0.00 && item.redimientosAsignatura[index].valoracion <= 5.00) || 'error'; }]},on:{"change":function($event){return _vm.actualizarNota(item,index)}},model:{value:(item.redimientosAsignatura[index].valoracion),callback:function ($$v) {_vm.$set(item.redimientosAsignatura[index], "valoracion", $$v)},expression:"item.redimientosAsignatura[index].valoracion"}})],1)]}}}),{key:"item.notaDefinitiva",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"40px"}},[_c('v-text-field',{attrs:{"rules":[function () { return (item.notaDefinitiva >= 0.00 && item.notaDefinitiva <= 5.00) || 'error'; }],"readonly":!_vm.notaDefinitivaEditable},on:{"change":function($event){return _vm.actualizarNotaDefinitivaManual(item,_vm.index)}},model:{value:(item.notaDefinitiva),callback:function ($$v) {_vm.$set(item, "notaDefinitiva", $$v)},expression:"item.notaDefinitiva"}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }