<template>
    <DocenteNotas></DocenteNotas>
</template>
<script>
import DocenteNotas from "@/components/DocenteNotas.vue"
export default {
    components:{
        DocenteNotas,

    }
}
</script>